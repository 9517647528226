document.addEventListener('turbo:load', () => {
  $("#close-sidebar").click(function () {
    $(".page-wrapper").removeClass("toggled");
  });
  $("#show-sidebar").click(function () {
    $(".page-wrapper").addClass("toggled");
  });

  // Enable on all forms
  $('form').areYouSure();
});

document.addEventListener('turbo:before-stream-render', () => {
  $(".turbo-flash-outer-wrapper .alert").delay(2000).fadeOut(500, function () {
    $(".turbo-flash-outer-wrapper .alert").alert('close');
  });

  $('form').areYouSure();
});

document.addEventListener('turbo:click', () => {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

document.addEventListener('turbo:submit-start', () => {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

document.addEventListener('turbo:before-fetch-request', () => {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

document.addEventListener('turbo:submit-end', () => {
  Turbo.navigator.delegate.adapter.progressBar.setValue(1);
  Turbo.navigator.delegate.adapter.hideProgressBar();
  Turbo.navigator.delegate.adapter.progressBar.setValue(0);
  if ($('select').first().data('select2') != undefined) $('select:not([ignoreselect2="true"])').select2('destroy');
});

document.addEventListener('turbo:before-fetch-response', () => {
  Turbo.navigator.delegate.adapter.progressBar.setValue(1);
  Turbo.navigator.delegate.adapter.hideProgressBar();
  Turbo.navigator.delegate.adapter.progressBar.setValue(0);
  if ($('select').first().data('select2') != undefined) $('select:not([ignoreselect2="true"])').select2('destroy');
});

//
// document.addEventListener('turbo:click ', () => {
//   alert('turbo:click ');
// });
//
// document.addEventListener('turbo:before-visit', () => {
//   alert('turbo:before-visit');
// });
//
// document.addEventListener('turbo:visit', () => {
//   alert('turbo:visit');
// });
//
// document.addEventListener('turbo:submit-start', () => {
//   alert('turbo:submit-start');
// });
//
// document.addEventListener('turbo:before-fetch-request', () => {
//   alert('turbo:before-fetch-request');
// });
//
// document.addEventListener('turbo:before-fetch-response', () => {
//   alert('turbo:before-fetch-response');
// });
//
// document.addEventListener('turbo:submit-end', () => {
//   alert('turbo:submit-end');
// });
//
// document.addEventListener('turbo:before-cache', () => {
//   alert('turbo:before-cache');
// });
//
// document.addEventListener('turbo:before-render', () => {
//   alert('turbo:before-render');
// });
//
// document.addEventListener('turbo:before-stream-render', () => {
//   alert('turbo:before-stream-render');
// });
//
// document.addEventListener('turbo:render', () => {
//   alert('turbo:render');
// });
//
// document.addEventListener('turbo:load', () => {
//   alert('turbo:load');
// });
//
// document.addEventListener('turbo:frame-render', () => {
//   alert('turbo:frame-render');
// });
//
// document.addEventListener('turbo:frame-load', () => {
//   alert('turbo:frame-load');
// });
//
