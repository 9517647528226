import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["addOptionType", "addOptionValue", "addImprintLocation", "template"]

  addAssociation(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, Math.floor(Math.random() * 20));
    if (this.hasAddOptionTypeTarget) {
      this.addOptionTypeTarget.insertAdjacentHTML('beforebegin', content)
    } else if (this.hasAddOptionValueTarget) {
      this.addOptionValueTarget.insertAdjacentHTML('beforebegin', content)
    } else {
      this.addImprintLocationTarget.insertAdjacentHTML('beforebegin', content)
    }
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
  }
}
