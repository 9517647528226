// =========================================================
// Checkbox Grid
// Documentation at ./docs/checkbox-grid.md
// =========================================================

import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["hidden"]
  static targets = [
    "row",
    "column",
    "rowCheckbox",
    "columnCheckbox",
    "cellCheckbox",
    "showRowDropdown",
    "showColumnDropdown",
  ]

  // Called on init, enable column/row checkboxes, set initial state
  connect() {
    this.rowCheckboxTargets.forEach(x => x.removeAttribute("disabled"))
    this.columnCheckboxTargets.forEach(x => x.removeAttribute("disabled"))

    // Dispatch native events so that stimulus can catch them
    $(this.showRowDropdownTarget).on('select2:select', function() {
      this.dispatchEvent(new Event('change', { bubbles: true }))
    })
    $(this.showColumnDropdownTarget).on('select2:select', function() {
      this.dispatchEvent(new Event('change', { bubbles: true }))
    })

    this.update()
  }

  // Called to update the state of column/row checkboxes, either checked/unchecked/indeterminate
  update() {
    let hiddenRowsIds = this.hiddenRowsIds()
    let hiddenColumnIds = this.hiddenColumnIds()

    let rowCount = this.rowCheckboxTargets
      .filter(x => !hiddenRowsIds.has(x.dataset.rowId))
      .length

    let columnCount = this.columnCheckboxTargets
      .filter(x => !hiddenColumnIds.has(x.dataset.columnId))
      .length

    function updateState(el, checkedCount, totalCount){
      if (checkedCount == totalCount) {
        // All checked
        el.checked = true
        el.indeterminate = false
      }
      else if (checkedCount > 0) {
        // Some checked
        el.checked = false
        el.indeterminate = true
      }
      else {
        // None checked
        el.checked = false
        el.indeterminate = false
      }
    }

    this.rowCheckboxTargets.forEach((el) => {
      let rowId = el.dataset.rowId
      let checkedCount = this.cellCheckboxTargets
        .filter(x => x.dataset.rowId == rowId)
        .filter(x => !hiddenColumnIds.has(x.dataset.columnId))
        .filter(x => x.checked)
        .length

      updateState(el, checkedCount, columnCount)
    })

    this.columnCheckboxTargets.forEach((el) => {
      let columnId = el.dataset.columnId
      let checkedCount = this.cellCheckboxTargets
        .filter(x => x.dataset.columnId == columnId)
        .filter(x => !hiddenRowsIds.has(x.dataset.rowId))
        .filter(x => x.checked)
        .length

      updateState(el, checkedCount, rowCount)
    })
  }

  // Called when a column/row checkbox is clicked, to check/uncheck its cells
  toggle(event) {
    let checked = event.currentTarget.checked
    let rowId = event.currentTarget.dataset.rowId
    let columnId = event.currentTarget.dataset.columnId
    let cellCheckboxes

    let hiddenRowsIds = this.hiddenRowsIds()
    let hiddenColumnIds = this.hiddenColumnIds()

    // Pick either all matching row or column checkboxes
    if (rowId) {
      cellCheckboxes = this.cellCheckboxTargets
        .filter(x => x.dataset.rowId == rowId)
        .filter(x => !hiddenColumnIds.has(x.dataset.columnId))
    }
    else if (columnId) {
      cellCheckboxes = this.cellCheckboxTargets
        .filter(x => x.dataset.columnId == columnId)
        .filter(x => !hiddenRowsIds.has(x.dataset.rowId))
    }
    else {
      console.error("Checkbox has neither row nor column ID!")
    }

    // Update their state
    cellCheckboxes.forEach(x => x.checked = checked)

    // Call update to refresh state of other column/row checkboxes
    this.update()
  }

  hiddenRowsIds() {
    return this.rowTargets
    .filter(x => x.classList.contains(this.hiddenClass))  
    .map(x => x.dataset.rowId)
    .reduce((j, k) => j.add(k), new Set())
  }

  hiddenColumnIds() {
    return this.columnTargets
      .filter(x => x.classList.contains(this.hiddenClass))  
      .map(x => x.dataset.columnId)
      .reduce((j, k) => j.add(k), new Set())
  }

  // Called when the show row dropdown selection changes, to show the chosen row in the grid
  showRow(event) {
    let rowId = this.showRowDropdownTarget.value
    this.rowTargets
      .filter(x => x.dataset.rowId == rowId)  
      .forEach(x => x.classList.remove(this.hiddenClass))

    this.showRowDropdownTarget.value = null
    this.update()
  }

  // Called when the show column dropdown selection changes, to show the chosen column in the grid
  showColumn(event) {
    let columnId = this.showColumnDropdownTarget.value
    this.columnTargets
      .filter(x => x.dataset.columnId == columnId)  
      .forEach(x => x.classList.remove(this.hiddenClass))

    this.showColumnDropdownTarget.value = null
    this.update()
  }
}
