import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  disconnect() {
    (function () {
      $('select:not([ignoreselect2="true"])').select2( { theme: 'bootstrap4' } )
    })();
  }
}
