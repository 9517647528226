import { Controller } from 'stimulus';

import "select2/dist/css/select2";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
import select2 from "select2";

export default class extends Controller {
  connect() {
    console.log('in oli controller');
    console.log(select2)

    // $("#order_line_item_variant_id").on('select2:select', function () {
    //   console.log("list item selected");
    //   let event = new Event('change', { bubbles: true }) // fire a native event
    //   this.dispatchEvent(event);
    // });


    $('#order_line_item_variant_id').select2({
      minimumInputLength: 3,
      ajax: {
        type: "GET",
        url: "/variants",
        dataType: 'json',
        data: function(params) {
          return {
            q: params.term
          };
        },
        processResults: function (data) {
          return {
            results: data.map(function(v) {
              return {
                text: v.name,
                id: v.id
              };
            })
          };
        },
        success: (data) => {

          console.log('Test json received!')
        }
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      }
    });

  }

  revealForm() {
    console.log('inhere')
    let form = document.querySelector("#new-order-line-item")
    let button = document.querySelector("#add-order-line-item-button")
    form.classList.remove("d-none")
    button.classList.add("d-none")
  }


  revealQuantityForm(e) {
    let name = document.getElementById(`item_${e.target.dataset.id}_name`)
    let name_form = document.getElementById(`item_${e.target.dataset.id}_name_form`)
    let quantity = document.getElementById(`item_${e.target.dataset.id}_quantity`)
    let quantity_form = document.getElementById(`item_${e.target.dataset.id}_quantity_form`)
    name.classList.add('d-none')
    name_form.classList.remove('d-none')
    quantity.classList.add('d-none')
    quantity_form.classList.remove('d-none')
    cancel_button.classList.remove('d-none')
  }

  hideQuantityForm(e) {
    let name = document.getElementById(`item_${e.target.dataset.id}_name`)
    let name_form = document.getElementById(`item_${e.target.dataset.id}_name_form`)
    let quantity = document.getElementById(`item_${e.target.dataset.id}_quantity`)
    let quantity_form = document.getElementById(`item_${e.target.dataset.id}_quantity_form`)
    let cancel_button = document.getElementById(`cancel-quantity-button-${e.target.dataset.id}`)
    name.classList.remove('d-none')
    quantity.classList.remove('d-none')
    quantity_form.style.display = "none";
    cancel_button.style.display = "none";
  }

  variants() {
    console.log('helloworld')
    $.ajax({
      type: "GET",
      url: "/variants",
      success: (data) => {
        console.log(data)
        console.log('Test json received!')
      }
    })
  }
}

