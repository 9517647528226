// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("packs/areyousure")
require("packs/common_logic")
require("bootstrap")
require("util")
require("trix")
require("@rails/actiontext")
require('@hotwired/turbo-rails')


// if (!window.__appClientLoaded) {
//   window.__appClientLoaded = true;
//
//   require('@hotwired/turbo-rails');
// }

import('stylesheets/application.scss')

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Rails from "@rails/ujs"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import 'bootstrap'
import 'select2'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css'
import "@fortawesome/fontawesome-free/css/all"
import "@fortawesome/free-brands-svg-icons"
import bsCustomFileInput from 'bs-custom-file-input';
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// Overrides default rails confirmation with bootstrap 4 confirm dialog
Rails.confirm = function(message, element) {
    let $element = $(element)
    let $dialog = $('#confirmModal')
    let $content = $dialog.find('#confirmModalContent')
    let $ok = $dialog.find('#ok-button')
    $content.text(message)
    $ok.click(function(event) {
        event.preventDefault()
        $dialog.modal("hide")
        let old = Rails.confirm
        Rails.confirm = function() { return true }
        $element.get(0).click()
        Rails.confirm = old
    })
    $dialog.modal("show")
    return false
}

window.jQuery = window.$ = require('jquery')

$(document).ready(function() {
  bsCustomFileInput.init();
})

document.addEventListener('turbo:before-cache', () => {
  if ($('select').first().data('select2') != undefined) $('select:not([ignoreselect2="true"])').select2('destroy');
})

document.addEventListener('turbo:submit-end', () => {

    $('select:not([ignoreselect2="true"])').select2( { theme: 'bootstrap4' } )
    $('form').areYouSure();

    window.successModal = function(html) {
        $("#successModal .modal-body").html(html)
        $("#successModal").modal('show')
    }
})

document.addEventListener('turbo:load', () => {
    $('select:not([ignoreselect2="true"])').select2( { theme: 'bootstrap4' } )
    $('form').areYouSure();

    window.successModal = function(html) {
        $("#successModal .modal-body").html(html)
        $("#successModal").modal('show')
    }
})

document.addEventListener('turbo:before-fetch-response', () => {
    $('select:not([ignoreselect2="true"])').select2( { theme: 'bootstrap4' } )
    $('form').areYouSure();

    window.successModal = function(html) {
        $("#successModal .modal-body").html(html)
        $("#successModal").modal('show')
    }
})


function fadeRowOut(event) {
}
import "controllers"
