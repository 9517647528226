import { Controller } from "stimulus"

export default class extends Controller {

  removeAfterSubmit(e) {
    document.addEventListener("ajax:success", function(){
      console.log(e)
      console.log(this)
      console.log(e.srcElement.dataset.planLineProduct)
      let card = document.getElementById(e.srcElement.dataset.planLineProduct);
      card.remove();
    })
  }

}
