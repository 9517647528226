import { Controller } from 'stimulus';
import { FetchRequest, patch } from '@rails/request.js'
import $ from 'jquery';
import 'select2'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css'

export default class extends Controller {
  connect() {
    console.log('hello-world')

    const initSelect2 = () => {
        $(function() {
            $("#order_line_item_variant_id").select2();
        });
    };

    document.addEventListener("turbo:load", function() {
      initSelect2();
    });
  }

  updateVariantOptions() {
    console.log(event)
    console.log('fire stimulus action')
  }
}
