import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['selector']

  connect() {
    $(this.selectorTarget).select2({
      tags: true,
      tokenSeparators: [',', ' ']
    })
  }
}
