import { Controller } from "stimulus";
import { FetchRequest, patch } from '@rails/request.js'
let debounce = require('lodash/debounce');

export default class extends Controller {

  initialize() {
    this.autosave = debounce(this.autosave, 700).bind(this)
  };

  async autosave(event) {
    console.log(event)
    const model = event.target.dataset.model
    const column_name = event.target.dataset.columnName
    const result = event.target.value
    console.log(model)
    const request = new FetchRequest("patch",
                                     event.target.dataset.autoSaveUrl,
                                     { body: JSON.stringify({
                                                              [model]: {
                                                                [column_name]: result
                                                              }
                                                            } ),
                                           responseKind: "turbo-stream"

                                    })
    const response = await request.perform()
    console.log(response)
    if (response.ok) {
      console.log('success')

      $(".turbo-flash-outer-wrapper .alert").delay(2000).fadeOut(500, function () {
        $(".turbo-flash-outer-wrapper .alert").alert('close');
      });

      $('form').areYouSure();
    } else {

      console.log('failure');
      $("#errorModal").modal('show');
    }
  };
}
