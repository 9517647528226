import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log('oh hai reset_form_controller.js')
  }

  reset() {
    let form = this.element
    form.reset();
    $('select').val(null).trigger('change');
  }

  testAction(){
    e.preventDefault()
    console.log('inhere')
  }
}
