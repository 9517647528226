import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["name"]

  connect() {
    console.log('connected')
    console.log('inhello')
    setTimeout(function(){
      $('select:not([ignoreselect2="true"])').select2( { theme: 'bootstrap4' } )
    }, 1000)

  }

  test() {
    console.log('hello in here')
  }

  greet() {
    const element = this.nameTarget
    const name = element.value
    console.log(`Hello, ${name}!`)
  }
}
