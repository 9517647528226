import { Controller } from "stimulus"
import select2 from "select2";

export default class extends Controller {
  submit(event){
    var hidden_fields = document.getElementsByClassName("hidden-sales-channel-id");
    for (var i = 0; i < hidden_fields.length; i++) {
      hidden_fields[i].value = $('#restock_sales_channel').find(':selected').val();
      console.log('set: ', $('#restock_sales_channel').find(':selected').val());
    }
  }
}
